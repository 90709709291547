import React, { Component , useState} from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl , ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import "./Albums.css";

import ReactDataGrid from "react-data-grid";
import { Toolbar, Data } from "react-data-grid-addons";

const selectors = Data.Selectors;
const defaultColumnProperties = {
  resizable: true,
  filterable: true
};
const COLUMN_WIDTH = 200;
const columns = [
 
  {
    key: "songName",
    name: "Song Name",
    width: COLUMN_WIDTH
  },
  {
    key: "songDescription",
    name: "Song Description",
    width: COLUMN_WIDTH
  },
  {
    key: "genereName",
    name: "Genere Name",
    width: COLUMN_WIDTH,
    
    editable: false
  },
  {
    key: "songFileName",
    name: "Song File Name",
    width: COLUMN_WIDTH + 100
    
  }
].map(c => ({ ...c, ...defaultColumnProperties }));

const EmptyRowsView = () => {
    const message = "No Song added to the album";
    return (
      <div
        style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
      >
        <h3>{message}</h3>
      </div>
    );
  };
//"{\"singer\":\"test singer\",\"songFile\":\"music1.mp3\", 
//\"albumName\":\"Album 1\",\"title\":\"Title1\"}",
export default class Albums extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDeleting: null,
      albumTitle:"",
      singerName:"",
      album: null,
      rows: null,
      filteredRows:[],
      filters:{}


    };
  }

  async componentDidMount() {
    try {
      
      const album = await this.getAlbum();
      const { albumTitle, singerName } = album;
  
      this.setState({
        album ,
        albumTitle ,
        singerName,
        rows: album.songs,
        filteredRows: album.songs
      });
    } catch (e) {
      alert(e);
    }
  }

  getAlbum() {
    return API.get("album", `/album/${this.props.match.params.id}`);
  }

  sveAlbum(album) {
    return API.put("album", `/album/${this.props.match.params.id}`, {
      body: album
    });
  }

  deleteAlbum() {
    return API.del("album", `/album/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.albumTitle.length > 0;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  
  handleSubmit = async event => {

    event.preventDefault();

   

    this.setState({ isLoading: true });

    try {
     

      await this.saveAlbum({
        content: this.state.album
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

   handleFilterChange (filter) {
    console.log(filter);
    const newFilters = this.state.filters;
    console.log(newFilters);
    if (filter.filterTerm ) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
  
    console.log(newFilters);
    if (newFilters[filter.column.key]) {
    this.setState({filters: newFilters,
      filteredRows : this.getRows(this.state.rows, newFilters)});
    } else {
      this.clearFilter();
    }
   
  
  }


  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this Album?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteAlbum();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

   getRows(rows, filters) {
     console.log(rows);
     console.log(filters);
    const sel =  selectors.getRows({ rows, filters });
    console.log(sel);
    return sel;
  }
  clearFilter() {
    this.setState({
      filteredRows: this.state.rows,
      filters: {}

    });
  }
  getSize() {
    return this.getRows().length;
  }

  render() {
   
    return (
      <div className="Albums">
        {this.state.album &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="albumTitle">
            <ControlLabel>Title</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.albumTitle}
                type="text"
                disabled={true}

              />

            </FormGroup>
            <FormGroup controlId="singerName">
            <ControlLabel>Singer Name</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.singerName}
                type="text"
                disabled={true}
              />
            </FormGroup>
         
            <ReactDataGrid
          columns={columns}
          rowGetter={i => this.state.filteredRows[i]}
          rowsCount={this.state.filteredRows.length}
          minHeight={200}
          emptyRowsView={EmptyRowsView}
          enableCellSelect = {false}
          toolbar={<Toolbar enableFilter={true} />}
          onAddFilter = {filter => this.handleFilterChange(filter)}
          onClearFilters={() => this.clearFilter()}
          
         
        />
         
          </form>}
      </div>
    );
  }
}
