const dev = {
    STRIPE_KEY: "pk_test_zEhGGDV2nupgGJaWFiPLPTkN00SvANmHqh",
    s3: {
      REGION: "us-east-1",
      BUCKET: "janmusic-api-dev-attachmentsbucket-1xd6ybrty0pus"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://c7mtt4vbv7.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_kKRRiZOj9",
      APP_CLIENT_ID: "59veao86fn1aug8g731jmv0u34",
      IDENTITY_POOL_ID: "us-east-1:f47b9701-33ae-4673-a3fe-77e87248e57a"
    }
  
  };
  
  const prod = {
    STRIPE_KEY: "pk_test_zEhGGDV2nupgGJaWFiPLPTkN00SvANmHqh",
  
    s3: {
      REGION: "us-east-1",
      BUCKET: "janmusic-api-prod-attachmentsbucket-fafkhajzdv3d"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://jvr77kshr6.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_19y9H6PCo",
      APP_CLIENT_ID: "2nup925qudb0n5rmmkv45j5qtk",
      IDENTITY_POOL_ID: "us-east-1:748412e9-67a8-4ede-9daf-3992445f71c5"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  