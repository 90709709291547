import React, {  Component} from "react";

import ReactDataGrid from "react-data-grid";
import LoaderButton from "../components/LoaderButton";
import { FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import { Editors } from "react-data-grid-addons";
import ReactDOM from "react-dom";
import { s3Upload } from "../libs/awsLib";
import { API } from "aws-amplify";
import uuid from "uuid";

import "./NewAlbum.css";

class FileEditor extends React.Component {

    
    constructor(props) {
      super(props);
      this.file = null;
      this.state = { fileName: props.value , file: null};
    }
  
    getValue() {
      return { songFileName: this.state.fileName, songFile: this.state.file };
    }

    handleFileChange = event => {
       // this.file = event.target.files[0];

       if (!event.target.files[0]) return;
       this.setState({fileName: event.target.files[0].name, file:event.target.files[0] });
        
        if (event.target.files[0].size > 1000000 ){
            alert(`Please pick a file smaller than ${1000000/1000000} MB.`);
            this.setState({fileName: "", file:null });
            return;
          }
          
        console.log(event.target.files[0]);
      }
      getInputNode() {
        return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
      }
  

    render() {
      return (
        <FormGroup controlId="file">
        <FormControl onChange={this.handleFileChange} type="file" />
      </FormGroup>
      );
    }
  }

const rowsOutside = [{songName:"", id:0}];
const defaultColumnProperties = {
  resizable:true
};

const COLUMN_WIDTH = 200;
const { DropDownEditor } = Editors;
const genereTypes = [
    { id: "rock", value: "Rock" },
    { id: "pop", value: "Pop" },
    { id: "jazz", value: "Jazz" }
  ];

  function allowEdit(rowData) {
    if (rowData) {
      if (rowData.id) {
        return rowData.id !== 0 ;

      }
    }
    return false;
  
  }
const genereTypeEditor = <DropDownEditor options={genereTypes} />;
const columns = [
 
  {
    key: "songName",
    name: "Song Name",
    width: COLUMN_WIDTH,
    editable: function(rowData) {
        
        return allowEdit(rowData);
      }
  },
  {
    key: "songDescription",
    name: "Song Description",
    width: COLUMN_WIDTH,
    editable: function(rowData) {
      
      return allowEdit(rowData);
      }
  },
  {
    key: "genereName",
    name: "Genere Name",
    width: COLUMN_WIDTH,
    editor: genereTypeEditor,
    editable: function(rowData) {
       
      return allowEdit(rowData);
      }
  },
  {
    key: "songFileName",
    name: "Song File Name",
    editor: FileEditor,
    width: COLUMN_WIDTH + 100,
    tooltip: "Double click or enter to select a file",
    editable: function(rowData) {
        
      return allowEdit(rowData);
      }
  }
].map(c => ({ ...c, ...defaultColumnProperties }));


const ROW_COUNT = 5;

const EmptyRowsView = () => {
    const message = "No Song added to the album";
    return (
      <div
        style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
      >
        <h3>{message}</h3>
      </div>
    );
  };

  
export default class NewAlbum extends Component {

    constructor(props) {
        super(props);
    
        this.file = null;
    
        this.state = {
          isLoading: null,
          singerName: "",
          albumTitle:"",
          editable: false,
          rows: rowsOutside
        };
  
      }
      validateForm() {
        const validInput = this.state.singerName.length > 0
        && this.state.albumTitle.length > 0
        && this.state.rows.length > 1;

        var validSongs = true;
        if (validInput) {
        for (var i=1; i < this.state.rows.length; i++) {
            console.log(this.state.rows[i]);
            if (this.state.rows[i].songName === "" ||
            this.state.rows[i].songFile == null) {
                validSongs = false;
            }

        }
    }
        return validInput && validSongs;
      }

      getCellActions(column, row) {
        const addRowsActions = [
            {
              icon: <span className="glyphicon glyphicon-plus" />,
              callback: () => {
                this.state.rows.push({id:uuid.v1(), songName:"", songFileName:"double click or enter to select a file", genereName:"",   songFile:null});
                this.setState({editable:true});
              }
            }
          ];

          const removeRowsActions = [
           
            {
              icon: <span className="glyphicon glyphicon-remove" />,
              callback: () => {
                console.log(row);
                if (row.id !== 0) {
                let rows = this.state.rows.slice();
                rows = rows.filter(item => item.id !== row.id);
                this.setState({rows});
                console.log(rows);
                }
              }
            }
          ];


        const cellAddAction = {
          songFileName: addRowsActions
          };
        
          const cellRemoveAction = {
            songFileName: removeRowsActions
          };
     
          return row.id  === 0 ? cellAddAction[column.key] : cellRemoveAction[column.key];
        

      }

      handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
      }

      onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        console.log("on row update called");
        this.setState(state => {
          const rows = state.rows.slice();
          for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
          }
          return { rows };
        });
      };

      createAlbum(album) {
        console.log(JSON.stringify(album));
        return API.post("album", "/album", {
          body: album
        });
      }

      handleSubmit = async event => {
        event.preventDefault();
    
    
        this.setState({ isLoading: true });
    
        try {

            console.log(this.state.rows);
            for(var i =1; i<this.state.rows.length; i++) {
                console.log(this.state.rows[i]);
                const songFile = this.state.rows[i].songFile ? await s3Upload(this.state.rows[i].songFile ) : null;
                this.state.rows[i].songFileName = songFile;
                this.state.rows[i].songFile = null;
                console.log(this.state.rows[i]);
                // create song
                // create Album
            }

            await this.createAlbum({
                
                singerName: this.state.singerName,
                
                albumTitle : this.state.albumTitle,
                songs: this.state.rows.slice(1, this.state.rows.length)
              });
            this.setState({ isLoading: false });
            this.props.history.push("/");
        /**  const songFile = this.file
            ? await s3Upload(this.file)
            : null;
    
          await this.createSong({
            songFile,
            singer: this.state.singer,
            albumName: this.state.albumName,
            title : this.state.title
          });
          this.props.history.push("/");*/ 
        } catch (e) {
          alert(e);
          this.setState({ isLoading: false });
        }
      }
    render() {
        
        return (
        <div>
            <form onSubmit={this.handleSubmit}>
        <div >
  
        <FormGroup   controlId="albumTitle">
            <ControlLabel>Title</ControlLabel>
            <FormControl
              type="text"
              value={this.state.albumTitle}
              onChange={this.handleChange}
              placeholder="Title of the Album"
            />
             
            </FormGroup>
          
              <FormGroup  controlId="singerName">
            <ControlLabel>Singer Name</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.singerName}
                type="text"
                placeholder="Singer Name"
              />
            </FormGroup>
           
          
  
        </div>
      <div>
      <FormGroup   controlId="title">
            <ControlLabel>Add Songs</ControlLabel>
            </FormGroup>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => this.state.rows[i]}
          rowsCount={ROW_COUNT}
          minHeight={200}
          emptyRowsView={EmptyRowsView}
          onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
          enableCellSelect={this.state.editable}
          getCellActions={this.getCellActions.bind(this)}
         
        />
         <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving..."
          />
      </div>
      </form>
        </div>
        ) ;
    }
}

